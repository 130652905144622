import React from "react"
import { css } from "@emotion/react"
import Seo from "~components/Seo"
import Section from "~components/Section"
import ForgotForm from "~components/blocks/ForgotForm"
import { breakpoints } from '~styles/global'

const Forgot = () => {
  const { mobile, tablet } = breakpoints
  return (
    <>
    <Seo title="Forgot" url="/forgot"/>
    <Section>
			<ForgotForm 
				css={css`
					grid-column: 3 / 6;
					margin-top: 93px;
					${tablet}{
						grid-column: 2 / 6;
					}
					${mobile}{
						grid-column: span 12;
					}
				`} />
		</Section>
    </>
  )
}

export default Forgot
