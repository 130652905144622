import React, { useState } from 'react'
import { Link, navigate } from "gatsby"
import axios from 'axios'
import { css } from '@emotion/react'
import { breakpoints } from '~styles/global'
import Button from '~components/blocks/Button'
import { useAccount } from '~context/siteContext'
import { useQueryParams, StringParam } from 'use-query-params'
import RichText from '~components/RichText'

const LoginForm = ({ className, text }) => {
  const { tablet } = breakpoints
  const [values, setValues] = useState({email: "", password: ""})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
  const { account, setAccount } = useAccount()
  const [urlParams, setUrlParams] = useQueryParams({
    redirect: StringParam
  })

  const getToken = e => {
    e.preventDefault()
    setLoading(true)
    axios({
      url: '/api/recover',
      method: 'post',
      data: {
        email: values.email,
      }
    }).then(result => {
      setLoading(false)
      if(result.data?.errors){
        setError(result.data?.errors[0].message)
      } else if (result.data?.data?.customerRecover?.userErrors?.length > 0) {
				setError(result.data?.data?.customerRecover?.userErrors[0].message)
			} else {
        setError(false)
        setSuccess(true)
      }
      return null
    })
    .catch(error => {
      setLoading(false)
      console.log(error)
    })
    return false
  }

  return (
    <form
      className={className}
      onSubmit={e => getToken(e)}
      css={css`
        background: var(--beige);
        cursor: ${loading ? 'wait' : ''};
        * {
          cursor: ${loading ? 'wait' : ''};
        }

        h2{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 36px;
          }
        }

        .h6{
          margin-bottom: 45px;
          ${tablet}{
            margin-bottom: 20px;
          }
        }

        input{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 18px;
          }
        }

        > .h5{
          margin-bottom: 42px;
          display: inline-block;
          ${tablet}{
            margin-top: 12px;
            margin-bottom: 30px;
          }
        }
      `}
    >
        <h2>Enter your email</h2>
				{!success &&
					<>
						<div className="h6"><RichText content={text}/></div>
						<input
							type="email"
							placeholder="Email"
							onFocus={() => setError(false)}
							onChange={e => setValues({...values, email: e.target.value})}
						/>
					</>
				}

				{success &&
					<div className="h6">We've sent instructions to your email.</div>
				}
        
        {error &&
          <div css={css`
            grid-column: span 2;
            margin-top: 20px;
            margin-bottom: 20px;
            color: var(--orange);
          `}>
            <p className="h5">{error}</p>
          </div>
        }
				{!success &&
					<Button onClick={() => null} type="submit">
						{loading ? 'Loading' : 'Send reset email'}
					</Button>
				}
        
    </form>
  )
}

LoginForm.propTypes = {
}

LoginForm.defaultProps = {
}

export default LoginForm
